import {Card, CardBody, CardHeader} from 'reactstrap';

import {FormikInput} from '@reasoncorp/kyber-js';

type Props = {
  formEditable: boolean
}

const CapComments = ({
                       formEditable
                     }: Props) => {
  return <Card className="mb-4">
    <CardHeader className="font-weight-bold">
      Comments
    </CardHeader>
    <CardBody>
      <FormikInput name="amarComments"
                   labelText="PA660 Comments"
                   formGroupClass="larger-label"
                   type="textarea"
                   maxLength="500"
                   disabled={true}/>
      <FormikInput name="comments"
                   labelText="Overall Comments"
                   formGroupClass="larger-label"
                   type="textarea"
                   maxLength="500"
                   disabled={!formEditable}/>
    </CardBody>
  </Card>;
};

export default CapComments;