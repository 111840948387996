import {ReviewSheetV3} from '../../types/reviewSheet';
import {CapV3, CapV3FormFields} from '../../types/cap';
import {LocalUnitAuditAction} from '../../enum';

const capV3Utils = {
  getInitialValues: (reviewSheet: ReviewSheetV3, cap: CapV3): CapV3FormFields => ({
    saveAction: LocalUnitAuditAction.CAP_SAVE,
    version: reviewSheet.version,
    assessmentRollAnalysis: {
      ...reviewSheet.assessmentRollAnalysis,
      economicConditionFactorsMet: reviewSheet.assessmentRollAnalysis.economicConditionFactorsMet,
      economicConditionFactorsMetDate: cap.assessmentRollAnalysis.economicConditionFactorsMetDate ?? '',
      economicConditionFactorsMetComments: cap.assessmentRollAnalysis.economicConditionFactorsMetComments ?? '',
      landValueDeterminationsAppropriate: reviewSheet.assessmentRollAnalysis.landValueDeterminationsAppropriate,
      landValueDeterminationsAppropriateDate: cap.assessmentRollAnalysis.landValueDeterminationsAppropriateDate ?? '',
      landValueDeterminationsAppropriateComments: cap.assessmentRollAnalysis.landValueDeterminationsAppropriateComments ?? '',
      cashValueAgrees: reviewSheet.assessmentRollAnalysis.cashValueAgrees,
      cashValueAgreesComments: cap.assessmentRollAnalysis.cashValueAgreesComments ?? '',
      cashValueAgreesDate: cap.assessmentRollAnalysis.cashValueAgreesDate ?? ''
    },
    assessmentTechnicalAnalysis: {
      ...reviewSheet.assessmentTechnicalAnalysis,
      doesUseCamaDate: cap?.assessmentTechnicalAnalysis?.doesUseCamaDate ?? '',
      doesUseCamaComments: cap?.assessmentTechnicalAnalysis?.doesUseCamaComments ?? '',
      hasPovertyExemptionGuidelinesDate: cap?.assessmentTechnicalAnalysis?.hasPovertyExemptionGuidelinesDate ?? '',
      hasPovertyExemptionGuidelinesComments: cap?.assessmentTechnicalAnalysis?.hasPovertyExemptionGuidelinesComments ?? '',
      question5ResolutionDate: cap?.assessmentTechnicalAnalysis?.question5ResolutionDate ?? '',
      question5ResolutionComments: cap?.assessmentTechnicalAnalysis?.question5ResolutionComments ?? '',
      doesProvideOnlineInformationDate: cap?.assessmentTechnicalAnalysis?.doesProvideOnlineInformationDate ?? '',
      doesProvideOnlineInformationComments: cap?.assessmentTechnicalAnalysis?.doesProvideOnlineInformationComments ?? '',
      doesProvideContactInformationDate: cap?.assessmentTechnicalAnalysis?.doesProvideContactInformationDate ?? '',
      doesProvideContactInformationComments: cap?.assessmentTechnicalAnalysis?.doesProvideContactInformationComments ?? '',
      doesTrainSupportStaffDate: cap?.assessmentTechnicalAnalysis?.doesTrainSupportStaffDate ?? '',
      doesTrainSupportStaffComments: cap?.assessmentTechnicalAnalysis?.doesTrainSupportStaffComments ?? '',
      doesComplyWithFeeCollectionDate: cap?.assessmentTechnicalAnalysis?.doesComplyWithFeeCollectionDate ?? '',
      doesComplyWithFeeCollectionComments: cap?.assessmentTechnicalAnalysis?.doesComplyWithFeeCollectionComments ?? '',
      doesConductCanvassDate: cap?.assessmentTechnicalAnalysis?.doesConductCanvassDate ?? '',
      doesConductCanvassComments: cap?.assessmentTechnicalAnalysis?.doesConductCanvassComments ?? '',
      doesBoardReviewMeetRequirementsDate: cap?.assessmentTechnicalAnalysis?.doesBoardReviewMeetRequirementsDate ?? '',
      doesBoardReviewMeetRequirementsComments: cap?.assessmentTechnicalAnalysis?.doesBoardReviewMeetRequirementsComments ?? '',
      doesHaveAdequateProcessDate: cap?.assessmentTechnicalAnalysis?.doesHaveAdequateProcessDate ?? '',
      doesHaveAdequateProcessComments: cap?.assessmentTechnicalAnalysis?.doesHaveAdequateProcessComments ?? '',
      doesMeetSTCRequirementsDate: cap?.assessmentTechnicalAnalysis?.doesMeetSTCRequirementsDate ?? '',
      doesMeetSTCRequirementsComments: cap?.assessmentTechnicalAnalysis?.doesMeetSTCRequirementsComments ?? '',
      wasAuditorMadeAwareDate: cap?.assessmentTechnicalAnalysis?.wasAuditorMadeAwareDate ?? '',
      wasAuditorMadeAwareComments: cap?.assessmentTechnicalAnalysis?.wasAuditorMadeAwareComments ?? '',
      didLocalUnitGrantPovertyExemptionsDate: cap?.assessmentTechnicalAnalysis?.didLocalUnitGrantPovertyExemptionsDate ?? '',
      question14ResolutionDate: cap?.assessmentTechnicalAnalysis?.question14ResolutionDate ?? '',
      question14ResolutionComments: cap?.assessmentTechnicalAnalysis?.question14ResolutionComments ?? '',
      hasImplementedCamaStandardsDate: cap?.assessmentTechnicalAnalysis?.hasImplementedCamaStandardsDate ?? '',
      hasImplementedCamaStandardsComments: cap?.assessmentTechnicalAnalysis?.hasImplementedCamaStandardsComments ?? ''
    },
    amarComments: reviewSheet.comments ?? '',
    comments: cap.comments ?? '',
    files: [],
    filesToDelete: []
  })
};

export default capV3Utils;