import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {
  AlertsProvider,
  configureRaygun,
  MiSuiteJurisdiction,
  MiSuiteRole,
  ssoUtils,
  User,
  UserProvider
} from '@reasoncorp/kyber-js';

import packageJson from '../package.json';
import './index.scss';
import config from './config';
import {ssoApi} from './api';
import {AppProvider} from './contexts';
import App from './App';

if (config.envName !== 'prod') {
  document.title = `${config.envName.toUpperCase()} - ${document.title}`;
}

const raygunOptions = {
  apiKey: process.env.REACT_APP_RAYGUN_API_KEY || 'apikeygoeshere',
  version: packageJson.version,
  tags: [config.envName]
};

configureRaygun(raygunOptions);

const setPermissions = (currentUser?: User) => {
  const isReasonUser = ssoUtils.hasAnyRolesForJurisdiction(currentUser, [
    MiSuiteRole.AUDITOR,
    MiSuiteRole.AUDIT_SUPPORT,
    MiSuiteRole.BRANCH_MANAGER
  ], MiSuiteJurisdiction.REASON_CONSULTING_CORP);
  const isStateAdmin = ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.PA_660_APP, MiSuiteRole.ADMIN);
  const isDepartmentalTechnician = ssoUtils.hasJurisdictionAndRole(currentUser, MiSuiteJurisdiction.PA_660_APP, MiSuiteRole.DEPARTMENTAL_TECHNICIAN);
  const isStateUser = isStateAdmin || isDepartmentalTechnician;
  const isLocalUnitAssessorOfRecord = ssoUtils.hasAnyRoles(currentUser, [MiSuiteRole.ASSESSOR_OF_RECORD]);

  return {
    isStateAdmin,
    isDepartmentalTechnician,
    isStateUser,
    isReasonUser,
    isBranchManager: ssoUtils.hasAnyRoles(currentUser, [MiSuiteRole.BRANCH_MANAGER]),
    isAuditor: ssoUtils.hasAnyRoles(currentUser, [MiSuiteRole.AUDITOR]),
    isAuditSupport: ssoUtils.hasAnyRoles(currentUser, [MiSuiteRole.AUDIT_SUPPORT]),
    isLocalUnitUser: ssoUtils.hasAnyRoles(currentUser, [
      MiSuiteRole.ASSESSOR_OF_RECORD,
      MiSuiteRole.EQUALIZATION_DIRECTOR
    ]),
    isLocalUnitAssessorOfRecord,
    hasFilingCabinetAccess: isReasonUser || isStateUser || isLocalUnitAssessorOfRecord
  };
};

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <UserProvider ssoApi={ssoApi} setPermissions={setPermissions}>
      <AppProvider>
        <AlertsProvider>
          <App/>
        </AlertsProvider>
      </AppProvider>
    </UserProvider>
  </BrowserRouter>
);