enum LocalUnitAuditStatus {
  NOT_STARTED = 'NOT_STARTED',
  REVIEW_SHEET_IN_PROGRESS = 'REVIEW_SHEET_IN_PROGRESS',
  REVIEW_SHEET_IN_REVIEW = 'REVIEW_SHEET_IN_REVIEW',
  REVIEW_SHEET_RETURNED = 'REVIEW_SHEET_RETURNED',
  REVIEW_SHEET_PENDING = 'REVIEW_SHEET_PENDING',
  SUBMITTED_TO_STATE = 'SUBMITTED_TO_STATE',
  ACCEPTED = 'ACCEPTED',
  ACCEPTED_WITH_TECHNICAL_ISSUES = 'ACCEPTED_WITH_TECHNICAL_ISSUES',
  CAP_REQUESTED = 'CAP_REQUESTED',
  CAP_RECEIVED = 'CAP_RECEIVED',
  CAP_DENIED = 'CAP_DENIED',
  NOTICE_RECEIVED = 'NOTICE_RECEIVED',
  ROLL_ASSUMED = 'ROLL_ASSUMED',
  ACCEPTED_WITHOUT_FOLLOW_UP = 'ACCEPTED_WITHOUT_FOLLOW_UP',
  ACCEPTED_WITH_FOLLOW_UP = 'ACCEPTED_WITH_FOLLOW_UP',
  EXCLUDED = 'EXCLUDED'
}

export default LocalUnitAuditStatus;