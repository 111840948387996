import {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Card, CardHeader, CustomInput} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon, CustomTable, useAlerts, useUserContext, windowUtils} from '@reasoncorp/kyber-js';

import {auditHistoryApi} from '../api';
import {CountyAudit, ExcludeIncludeModalState, LocalUnitAudit, Portal, RollbackModalState} from '../types';
import {LocalUnitAuditStatus} from '../enum';
import * as messages from '../messages';

type Props = {
  portal: Portal
  countyAudit: CountyAudit
  onExclude: (excludeIncludeModalState: ExcludeIncludeModalState) => void
  onRollback: (rollbackModalState: RollbackModalState) => void
}

const LocalUnitAuditsCard = ({
                               portal,
                               countyAudit,
                               onExclude,
                               onRollback
                             }: Props) => {
  const navigate = useNavigate();
  const {showErrorAlert} = useAlerts();
  const {permissions} = useUserContext();

  const handleReviewSheetClick = useCallback((localUnitAudit: LocalUnitAudit) => {
    navigate(`/${portal}-portal/county-audits/${countyAudit.id}/local-unit-audits/${localUnitAudit.id}/review-sheet`);
  }, [
    countyAudit.id,
    portal,
    navigate
  ]);

  const handleReviewSheetPdfClick = useCallback(async (localUnitAuditId: number) => {
    await windowUtils.openFileInNewWindow(
      auditHistoryApi.findReviewSheetDocument(localUnitAuditId),
      () => showErrorAlert(messages.UNABLE_TO_DOWNLOAD_DOCUMENT)
    );
  }, [
    showErrorAlert
  ]);

  const handleCapClick = useCallback((localUnitAudit: LocalUnitAudit) => {
    navigate(`/${portal}-portal/county-audits/${countyAudit.id}/local-unit-audits/${localUnitAudit.id}/cap`);
  }, [
    navigate,
    portal,
    countyAudit.id
  ]);

  const handleCapPdfClick = useCallback(async (localUnitAuditId: number) => {
    await windowUtils.openFileInNewWindow(
      auditHistoryApi.findCapDocument(localUnitAuditId),
      () => showErrorAlert(messages.UNABLE_TO_DOWNLOAD_DOCUMENT)
    );
  }, [
    showErrorAlert
  ]);

  const handleHistoryClick = useCallback((localUnitAudit: LocalUnitAudit) => {
    navigate(`/${portal}-portal/county-audits/${countyAudit.id}/local-unit-audits/${localUnitAudit.id}/audit-history`);
  }, [
    portal,
    navigate,
    countyAudit.id
  ]);

  const handleFilingCabinetClick = useCallback(async (localUnitAudit: LocalUnitAudit) => {
    navigate(`/${portal}-portal/county-audits/${countyAudit.id}/local-units/${localUnitAudit.localUnit.id}/${localUnitAudit.year}/filing-cabinet`);
  }, [
    navigate,
    portal,
    countyAudit.id
  ]);

  const ExcludedCell = useMemo(() => ({
                                        localUnitAudit
                                      }: {
    localUnitAudit: LocalUnitAudit
  }) => {
    if (localUnitAudit.offline) {
      return null;
    }

    const ariaLabel = `${localUnitAudit.localUnit.displayNameWithType} ${localUnitAudit.rawStatus === LocalUnitAuditStatus.EXCLUDED ? 'Excluded' : 'Included'}`;
    const excludeIncludeModalState: ExcludeIncludeModalState = {
      isOpen: true,
      action: localUnitAudit.rawStatus === LocalUnitAuditStatus.EXCLUDED ? 'include' as const : 'exclude' as const,
      localUnitAuditId: localUnitAudit.id,
      localUnitName: localUnitAudit.localUnit.displayNameWithType,
      submitting: false
    };

    return <CustomInput type="switch"
                        checked={localUnitAudit.rawStatus === LocalUnitAuditStatus.EXCLUDED}
                        id={`switch${localUnitAudit.id}`}
                        aria-label={ariaLabel}
                        onChange={() => onExclude(excludeIncludeModalState)}/>;
  }, [
    onExclude
  ]);

  const ReviewSheetCell = useMemo(() => ({
                                           localUnitAudit
                                         }: {
    localUnitAudit: LocalUnitAudit
  }) => {
    if (localUnitAudit.rawStatus === LocalUnitAuditStatus.EXCLUDED) {
      return null;
    } else if ((portal === 'reason' && localUnitAudit.rawStatus === LocalUnitAuditStatus.SUBMITTED_TO_STATE) ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.ACCEPTED ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.ACCEPTED_WITH_TECHNICAL_ISSUES ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.ROLL_ASSUMED ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.ACCEPTED_WITHOUT_FOLLOW_UP ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.ACCEPTED_WITH_FOLLOW_UP ||
      localUnitAudit.hasCap) {
      return (
        <ButtonIcon icon="file-pdf"
                    title={`${localUnitAudit.localUnit.displayNameWithType} Audit Review Sheet PDF`}
                    ariaLabel={`${localUnitAudit.localUnit.displayNameWithType} Audit Review Sheet PDF`}
                    className="text-primary"
                    onClick={() => handleReviewSheetPdfClick(localUnitAudit.id)}/>
      );
    } else {
      return (
        <ButtonIcon icon="clipboard"
                    title={`${localUnitAudit.localUnit.displayNameWithType} Audit Review Sheet`}
                    ariaLabel={`${localUnitAudit.localUnit.displayNameWithType} Audit Review Sheet`}
                    className="text-primary"
                    onClick={() => handleReviewSheetClick(localUnitAudit)}/>
      );
    }
  }, [
    handleReviewSheetClick,
    handleReviewSheetPdfClick,
    portal
  ]);

  const CapCell = useMemo(() => ({
                                   localUnitAudit
                                 }: {
    localUnitAudit: LocalUnitAudit
  }) => {
    if (localUnitAudit.rawStatus === LocalUnitAuditStatus.EXCLUDED) {
      return null;
    } else if (localUnitAudit.rawStatus === LocalUnitAuditStatus.CAP_REQUESTED
      || localUnitAudit.rawStatus === LocalUnitAuditStatus.CAP_RECEIVED
      || localUnitAudit.rawStatus === LocalUnitAuditStatus.CAP_DENIED
      || (localUnitAudit.hasCap && localUnitAudit.rawStatus === LocalUnitAuditStatus.NOTICE_RECEIVED)) {
      return (
        <ButtonIcon icon="clipboard"
                    title={`Latest ${localUnitAudit.localUnit.displayNameWithType} CAP`}
                    ariaLabel={`Latest ${localUnitAudit.localUnit.displayNameWithType} CAP`}
                    className="text-primary"
                    onClick={() => handleCapClick(localUnitAudit)}/>
      );
    } else if (localUnitAudit.hasCap) {
      return (
        <ButtonIcon icon="file-pdf"
                    title={`Latest ${localUnitAudit.localUnit.displayNameWithType} CAP PDF`}
                    ariaLabel={`Latest ${localUnitAudit.localUnit.displayNameWithType} CAP PDF`}
                    className="text-primary"
                    onClick={() => handleCapPdfClick(localUnitAudit.id)}/>
      );
    } else {
      return null;
    }
  }, [
    handleCapClick,
    handleCapPdfClick
  ]);

  const HistoryCell = useMemo(() => ({
                                       localUnitAudit
                                     }: {
    localUnitAudit: LocalUnitAudit
  }) => {
    if (localUnitAudit.rawStatus === LocalUnitAuditStatus.EXCLUDED) {
      return null;
    } else {
      return <ButtonIcon icon="history"
                         className="text-primary"
                         title={`${localUnitAudit.localUnit.displayNameWithType} history`}
                         ariaLabel={`${localUnitAudit.localUnit.displayNameWithType} history`}
                         onClick={() => handleHistoryClick(localUnitAudit)}/>;
    }
  }, [
    handleHistoryClick
  ]);

  const FilingCabinetCell = useMemo(() => ({
                                             localUnitAudit
                                           }: {
    localUnitAudit: LocalUnitAudit
  }) => {
    return <Button color="link"
                   onClick={() => handleFilingCabinetClick(localUnitAudit)}
                   className="FilingCabinetButton">
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon="folder-open"/>
        {localUnitAudit.filingCabinetCount > 0 &&
          <span className="fa-layers-counter filing-cabinet-badge">{localUnitAudit.filingCabinetCount}</span>}
      </span>
    </Button>;
  }, [
    handleFilingCabinetClick
  ]);

  const RollbackCell = useMemo(() => ({
                                        localUnitAudit
                                      }: {
    localUnitAudit: LocalUnitAudit
  }) => {
    if (localUnitAudit.rawStatus === LocalUnitAuditStatus.EXCLUDED ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.NOT_STARTED ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.SUBMITTED_TO_STATE ||
      localUnitAudit.offline) {
      return null;
    }

    const title = `Rollback ${localUnitAudit.localUnit.displayNameWithType} Audit`;
    return <ButtonIcon ariaLabel={title}
                       title={title}
                       onClick={() => onRollback({
                         isOpen: true,
                         localUnitAuditId: localUnitAudit.id,
                         localUnitName: localUnitAudit.localUnit.displayNameWithType,
                         submitting: false
                       })}
                       icon="caret-square-left"/>;
  }, [
    onRollback
  ]);

  const tableProps = useMemo(() => ({
    className: 'mb-0',
    items: countyAudit.localUnitAudits,
    headers: [
      {title: 'Local Unit Code', sortKey: 'localUnit.id', className: 'align-middle text-center text-nowrap'},
      {title: 'Local Unit Name', sortKey: 'localUnit.displayNameWithType', className: 'align-middle text-nowrap'},
      {title: 'Audit Status', sortKey: 'status', className: 'align-middle text-center text-nowrap'},
      {
        title: 'Excluded',
        sortKey: 'excluded',
        className: 'align-middle text-center text-nowrap',
        hide: portal !== 'reason' || countyAudit.submittedOn !== null
      },
      {title: 'Audit', className: 'align-middle text-center'},
      {title: 'CAP', className: 'align-middle text-center', hide: portal === 'reason'},
      {title: 'Filing Cabinet', className: 'align-middle text-center'},
      {title: 'Roll Back', className: 'align-middle text-center', hide: portal !== 'state' || !permissions.isStateAdmin},
      {title: 'History', className: 'align-middle text-center'}
    ],
    initialSort: {sortKey: 'localUnit.displayNameWithType', direction: 'asc' as const},
    renderRow: (localUnitAudit: LocalUnitAudit) => {
      return (
        <tr key={localUnitAudit.id}>
          <td className="align-middle text-center">{localUnitAudit.localUnit.id}</td>
          <td className="align-middle">{localUnitAudit.localUnit.displayNameWithType}</td>
          {localUnitAudit.offline && <>
            <td className="align-middle text-center">
              Offline
            </td>
            <td/>
            <td/>
            <td className="align-middle text-center">
              <FilingCabinetCell localUnitAudit={localUnitAudit}/>
            </td>
            <td/>
          </>}
          {!localUnitAudit.offline && <>
            <td className="align-middle text-center">
              {portal === 'reason' ? localUnitAudit.reasonPortalStatus : localUnitAudit.statePortalStatus}
            </td>
            {countyAudit.submittedOn === null &&
              <td className="align-middle text-center">
                <ExcludedCell localUnitAudit={localUnitAudit}/>
              </td>
            }
            <td className="align-middle text-center">
              <ReviewSheetCell localUnitAudit={localUnitAudit}/>
            </td>
            {portal === 'state' && <td className="align-middle text-center">
              <CapCell localUnitAudit={localUnitAudit}/>
            </td>}
            <td className="align-middle text-center">
              <FilingCabinetCell localUnitAudit={localUnitAudit}/>
            </td>
            {portal === 'state' && permissions.isStateAdmin && <td className="align-middle text-center">
              <RollbackCell localUnitAudit={localUnitAudit}/>
            </td>}
            <td className="align-middle text-center">
              <HistoryCell localUnitAudit={localUnitAudit}/>
            </td>
          </>}
        </tr>
      );
    }
  }), [
    permissions.isStateAdmin,
    countyAudit.localUnitAudits,
    countyAudit.submittedOn,
    portal,
    ReviewSheetCell,
    HistoryCell,
    CapCell,
    FilingCabinetCell,
    ExcludedCell,
    RollbackCell
  ]);

  return (
    <Card className="LocalUnitsCard">
      <CardHeader>{countyAudit.county.displayNameWithType} Local Units</CardHeader>
      <CustomTable {...tableProps} />
    </Card>
  );
};

export default LocalUnitAuditsCard;