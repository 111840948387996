import {ReviewSheetV2} from '../../types/reviewSheet';
import {CapV2, CapV2FormFields} from '../../types/cap';
import {LocalUnitAuditAction} from '../../enum';

const capV2Utils = {
  getInitialValues: (reviewSheet: ReviewSheetV2, cap: CapV2): CapV2FormFields => ({
    saveAction: LocalUnitAuditAction.CAP_SAVE,
    version: reviewSheet.version,
    assessmentRollAnalysis: {
      economicConditionFactorsMet: reviewSheet.assessmentRollAnalysis.economicConditionFactorsMet,
      economicConditionFactorsMetNotes: reviewSheet.assessmentRollAnalysis.economicConditionFactorsMetNotes ?? '',
      economicConditionFactorsMetResolution: cap.assessmentRollAnalysis.economicConditionFactorsMetResolution ?? '',
      economicConditionFactorsMetDate: cap.assessmentRollAnalysis.economicConditionFactorsMetDate ?? '',
      economicConditionFactorsMetComments: cap.assessmentRollAnalysis.economicConditionFactorsMetComments ?? '',
      landValueDeterminationsAppropriate: reviewSheet.assessmentRollAnalysis.landValueDeterminationsAppropriate,
      landValueDeterminationsAppropriateNotes: reviewSheet.assessmentRollAnalysis.landValueDeterminationsAppropriateNotes ?? '',
      landValueDeterminationsAppropriateResolution: cap.assessmentRollAnalysis.landValueDeterminationsAppropriateResolution ?? '',
      landValueDeterminationsAppropriateDate: cap.assessmentRollAnalysis.landValueDeterminationsAppropriateDate ?? '',
      landValueDeterminationsAppropriateComments: cap.assessmentRollAnalysis.landValueDeterminationsAppropriateComments ?? '',
      cashValueAgrees: reviewSheet.assessmentRollAnalysis.cashValueAgrees,
      cashValueAgreesNotes: reviewSheet.assessmentRollAnalysis.cashValueAgreesNotes ?? '',
      cashValueAgreesResolution: cap.assessmentRollAnalysis.cashValueAgreesResolution ?? '',
      cashValueAgreesDate: cap.assessmentRollAnalysis.cashValueAgreesDate ?? '',
      cashValueAgreesComments: cap.assessmentRollAnalysis.cashValueAgreesComments ?? ''
    },
    personalPropertyReview: {
      conductsAnnualPersonalPropertyCanvas: reviewSheet.personalPropertyReview.conductsAnnualPersonalPropertyCanvas,
      conductsAnnualPersonalPropertyCanvasNotes: reviewSheet.personalPropertyReview.conductsAnnualPersonalPropertyCanvasNotes ?? '',
      conductsAnnualPersonalPropertyCanvasDate: cap.personalPropertyReview.conductsAnnualPersonalPropertyCanvasDate ?? '',
      conductsAnnualPersonalPropertyCanvasComments: cap.personalPropertyReview.conductsAnnualPersonalPropertyCanvasComments ?? '',
      smallBusinessExemptionsGranted: reviewSheet.personalPropertyReview.smallBusinessExemptionsGranted,
      smallBusinessExemptionsGrantedNotes: reviewSheet.personalPropertyReview.smallBusinessExemptionsGrantedNotes ?? '',
      exemptionsProcessingRequirementMet: reviewSheet.personalPropertyReview.exemptionsProcessingRequirementMet,
      exemptionsProcessingRequirementMetNotes: reviewSheet.personalPropertyReview.exemptionsProcessingRequirementMetNotes ?? '',
      exemptionsProcessingRequirementMetDate: cap.personalPropertyReview.exemptionsProcessingRequirementMetDate ?? '',
      exemptionsProcessingRequirementMetComments: cap.personalPropertyReview.exemptionsProcessingRequirementMetComments ?? ''
    },
    reviewOfExemptionsGranted: {
      povertyExemptionsGranted: reviewSheet.reviewOfExemptionsGranted.povertyExemptionsGranted,
      povertyExemptionsGrantedNotes: reviewSheet.reviewOfExemptionsGranted.povertyExemptionsGrantedNotes ?? '',
      hasPovertyExemptionGuidelines: reviewSheet.reviewOfExemptionsGranted.hasPovertyExemptionGuidelines,
      hasPovertyExemptionGuidelinesNotes: reviewSheet.reviewOfExemptionsGranted.hasPovertyExemptionGuidelinesNotes ?? '',
      hasPovertyExemptionGuidelinesDate: cap.reviewOfExemptionsGranted.hasPovertyExemptionGuidelinesDate ?? '',
      hasPovertyExemptionGuidelinesComments: cap.reviewOfExemptionsGranted.hasPovertyExemptionGuidelinesComments ?? '',
      guidelinesIncludeAssetLevelTest: reviewSheet.reviewOfExemptionsGranted.guidelinesIncludeAssetLevelTest,
      guidelinesIncludeAssetLevelTestNotes: reviewSheet.reviewOfExemptionsGranted.guidelinesIncludeAssetLevelTestNotes ?? '',
      guidelinesIncludeAssetLevelTestDate: cap.reviewOfExemptionsGranted.guidelinesIncludeAssetLevelTestDate ?? '',
      guidelinesIncludeAssetLevelTestComments: cap.reviewOfExemptionsGranted.guidelinesIncludeAssetLevelTestComments ?? '',
      statutoryRequirementsMetAndPolicyFollowed: reviewSheet.reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowed,
      statutoryRequirementsMetAndPolicyFollowedNotes: reviewSheet.reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowedNotes ?? '',
      statutoryRequirementsMetAndPolicyFollowedDate: cap.reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowedDate ?? '',
      statutoryRequirementsMetAndPolicyFollowedComments: cap.reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowedComments ?? '',
      onlyStatutoryAuthorityItemsConsidered: reviewSheet.reviewOfExemptionsGranted.onlyStatutoryAuthorityItemsConsidered,
      onlyStatutoryAuthorityItemsConsideredNotes: reviewSheet.reviewOfExemptionsGranted.onlyStatutoryAuthorityItemsConsideredNotes ?? '',
      onlyStatutoryAuthorityItemsConsideredDate: cap.reviewOfExemptionsGranted.onlyStatutoryAuthorityItemsConsideredDate ?? '',
      onlyStatutoryAuthorityItemsConsideredComments: cap.reviewOfExemptionsGranted.onlyStatutoryAuthorityItemsConsideredComments ?? '',
      ptaFilingImposed: reviewSheet.reviewOfExemptionsGranted.ptaFilingImposed,
      ptaFilingImposedNotes: reviewSheet.reviewOfExemptionsGranted.ptaFilingImposedNotes ?? '',
      ptaFilingImposedDate: cap.reviewOfExemptionsGranted.ptaFilingImposedDate ?? '',
      ptaFilingImposedComments: cap.reviewOfExemptionsGranted.ptaFilingImposedComments ?? '',
      penaltyWaivedByResolution: reviewSheet.reviewOfExemptionsGranted.penaltyWaivedByResolution,
      penaltyWaivedByResolutionNotes: reviewSheet.reviewOfExemptionsGranted.penaltyWaivedByResolutionNotes ?? '',
      implementedCamaDataStandards: reviewSheet.reviewOfExemptionsGranted.implementedCamaDataStandards,
      implementedCamaDataStandardsNotes: reviewSheet.reviewOfExemptionsGranted.implementedCamaDataStandardsNotes,
      implementedCamaDataStandardsDate: cap.reviewOfExemptionsGranted?.implementedCamaDataStandardsDate ?? '',
      implementedCamaDataStandardsComments: cap.reviewOfExemptionsGranted?.implementedCamaDataStandardsComments ?? ''
    },
    amarComments: reviewSheet.comments ?? '',
    comments: cap.comments ?? '',
    files: [],
    filesToDelete: []
  })
};

export default capV2Utils;